import { track } from '../analytics';
/*
 * This class is responsible for interacting with Plaid without making UI decisions, so it can be used from different classes.
 * Those classes can inject your events behavior.
 */
export default class {
  addNew(params) {
    track("AddPaymentInfo");
    this.fetchTokenAndAddAccount('/contributions/fetch_link_token', params);
  }

  fetchTokenAndAddAccount (url, params) {
    let linkToken;

    fetch(url)
      .then((response) => response.json())
      .then(({ token }) => {
        linkToken = token;
        const handler = Plaid.create({
          token: token,
          onSuccess: (public_token, metadata) => {
            // Pass public_token to backend
            fetch("/profile/add_bank_account", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",

                "X-CSRF-Token": document
                  .querySelector('meta[name="csrf-token"]')
                  .getAttribute("content"),
              },
              body: JSON.stringify({
                public_token,
                metadata,
                old_access_token: params.accessToken
              })
            })
              .then((response) => response.json())
              .then((json) => {
                params.onSuccess(json)
              })
              .catch((error) => {
                console.error('error', error)
              })
          },
          onLoad: () => {
            handler.open()
          },
          onExit: (err, metadata) => {
            console.error('error', err);
            console.error('metadata', metadata);
          },
          onEvent: (eventName) => {
            if(eventName === 'EXIT') {
              params.onExit();
            }
          },
          receivedRedirectUri: null
        })
      })
  }
}
