import { Controller } from "stimulus";
import { track } from '../analytics';

export default class extends Controller {
  static targets = [
    "add",
    "remove",
    "verify",
    "accountList",
  ];

  remove(event) {
    event.preventDefault();
    this.removeTarget.disabled = true;
    this.removeTarget.textContent = "Removing...";

    fetch("/profile/remove_bank_account", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify({
        payment_method_id: event.target.dataset.paymentMethodId,
      }),
    })
      .then((response) => response.json())
      .then(({ ach_methods }) => {
        this.accountListTarget.innerHTML = ach_methods;
      });
  }

  verify(event) {
    event && event.preventDefault();
    track("VerifyPaymentInfo");
    const url = `/contributions/fetch_link_token?access_token=${event.target.dataset.accessToken}`;
    this.fetchTokenAndAddAccount(url, event);
  }

  addNew(event) {
    event && event.preventDefault();
    track("AddPaymentInfo");
    this.fetchTokenAndAddAccount('/contributions/fetch_link_token', event);
  }

  fetchTokenAndAddAccount (url, event) {
    let linkToken;

    fetch(url)
      .then((response) => response.json())
      .then(({ token }) => {
        linkToken = token;
        const handler = Plaid.create({
          token: token,
          onSuccess: (public_token, metadata) => {
            // Pass public_token to backend
            fetch("/profile/add_bank_account", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document
                  .querySelector('meta[name="csrf-token"]')
                  .getAttribute("content"),
              },
              body: JSON.stringify({
                public_token,
                metadata,
                old_access_token: event.target.dataset.accessToken
              })
            })
              .then((response) => response.json())
              .then(({ ach_methods }) => {
                this.accountListTarget.innerHTML = ach_methods;
              })
              .catch((error) => {
                console.error('error', error)
              })
          },
          onLoad: () => {
            handler.open()
          },
          onExit: (err, metadata) => {
            console.error('err', err)
            console.error('metadata', metadata)
          },
          onEvent: (eventName, metadata) => { },
          receivedRedirectUri: null
        })
      })
  }
}
