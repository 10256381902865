import { Controller } from "stimulus";
import { GoogleCharts } from "google-charts";
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../stylesheets/tailwind.config';

const fullConfig = resolveConfig(tailwindConfig)

export default class extends Controller {
  connect() {
    GoogleCharts.load(this.drawChart.bind(this));

    const anchor = document.getElementById("copyAnchor")
    if (anchor) anchor.addEventListener("click", this.setCopyShareLink);
  }

  /*
   * 1. Obtain data for contributions and grant requests
   * 2. Pass data to corresponding `createChart` function
   */
  drawChart() {
    let contributionData = this.getContributionData();
    let grantRequestsData = this.getGrantRequestsData();

    this.createContributionsAmountChart(contributionData);
    this.createContributionsCountChart(contributionData);
    this.createGrantRequestsCountChart(grantRequestsData);
  }

  // CHART 1 - Monthly Contributions
  createContributionsAmountChart(contributionData) {
    let amountData = google.visualization.arrayToDataTable([
      ["Year", "Total Contributions", "My Contributions"],
      [
        contributionData.month3.month,
        parseFloat(contributionData.month3.totalContributions),
        parseFloat(contributionData.month3.userContributions),
      ],
      [
        contributionData.month2.month,
        parseFloat(contributionData.month2.totalContributions),
        parseFloat(contributionData.month2.userContributions),
      ],
      [
        contributionData.month1.month,
        parseFloat(contributionData.month1.totalContributions),
        parseFloat(contributionData.month1.userContributions),
      ],
      [
        contributionData.month0.month,
        parseFloat(contributionData.month0.totalContributions),
        parseFloat(contributionData.month0.userContributions),
      ],
    ]);

    let formatter = new google.visualization.NumberFormat({
      prefix: "$",
    });
    formatter.format(amountData, 2);

    let colors = fullConfig.theme.colors;
    let brandfinance = colors.brandfinance.DEFAULT
    let brandgray = colors.brandgray.DEFAULT

    let options = {
      colors: [brandfinance, brandgray],
      vAxis: {
        gridlines: {
          color: 'transparent'
        }
      },
      legend: {
        position: "bottom",
      },
    };

    let chart = new google.visualization.AreaChart(
      document.getElementById("contribution_amount")
    );
    chart.draw(amountData, options);
  }

  // CHART 2 - Contribution Count
  createContributionsCountChart(contributionData) {
    let contributionCountData = google.visualization.arrayToDataTable([
      ["Year", "Contribution Count"],
      [
        contributionData.month3.month,
        parseFloat(contributionData.month3.contributionCount),
      ],
      [
        contributionData.month2.month,
        parseFloat(contributionData.month2.contributionCount),
      ],
      [
        contributionData.month1.month,
        parseFloat(contributionData.month1.contributionCount),
      ],
      [
        contributionData.month0.month,
        parseFloat(contributionData.month0.contributionCount),
      ],
    ]);

    let colors = fullConfig.theme.colors;
    let brandfinance = colors.brandfinance.DEFAULT
    let brandgray = colors.brandgray.DEFAULT
    
    let options = {
      colors: [brandfinance],
      vAxis: {
        gridlines: {
          color: 'transparent'
        }
      },
      legend: {
        position: "none",
      },
    };

    let chart = new google.visualization.AreaChart(
      document.getElementById("contribution_count")
    );
    chart.draw(contributionCountData, options);
  }

  // CHART 3 - Grant Requests Count
  createGrantRequestsCountChart(grantRequestsData) {
    let grantRequestData = google.visualization.arrayToDataTable([
      ["Year", "Grant Requests"],
      [
        grantRequestsData.month3.month,
        parseFloat(grantRequestsData.month3.count),
      ],
      [
        grantRequestsData.month2.month,
        parseFloat(grantRequestsData.month2.count),
      ],
      [
        grantRequestsData.month1.month,
        parseFloat(grantRequestsData.month1.count),
      ],
      [
        grantRequestsData.month0.month,
        parseFloat(grantRequestsData.month0.count),
      ],
    ]);

    let colors = fullConfig.theme.colors;
    let brandfinance = colors.brandfinance.DEFAULT
    let brandgray = colors.brandgray.DEFAULT

    let options = {
      colors: [brandfinance],
      vAxis: {
        gridlines: {
          color: 'transparent'
        }
      },
      legend: {
        position: "none",
      },
    };

    let chart = new google.visualization.AreaChart(
      document.getElementById("grant_requests")
    );
    chart.draw(grantRequestData, options);
  }

  // DATA - Contributions
  getContributionData() {
    let month0 = document.getElementById("contribution-month-0");
    let month1 = document.getElementById("contribution-month-1");
    let month2 = document.getElementById("contribution-month-2");
    let month3 = document.getElementById("contribution-month-3");

    return {
      month0: {
        month: month0.dataset.month,
        userContributions: month0.dataset.usercontributions,
        totalContributions: month0.dataset.totalcontributions,
        contributionCount: month0.dataset.contributioncount,
      },
      month1: {
        month: month1.dataset.month,
        userContributions: month1.dataset.usercontributions,
        totalContributions: month1.dataset.totalcontributions,
        contributionCount: month1.dataset.contributioncount,
      },
      month2: {
        month: month2.dataset.month,
        userContributions: month2.dataset.usercontributions,
        totalContributions: month2.dataset.totalcontributions,
        contributionCount: month2.dataset.contributioncount,
      },
      month3: {
        month: month3.dataset.month,
        userContributions: month3.dataset.usercontributions,
        totalContributions: month3.dataset.totalcontributions,
        contributionCount: month3.dataset.contributioncount,
      },
    };
  }

  // DATA - Grant Requests
  getGrantRequestsData() {
    let month0 = document.getElementById("grant-month-0");
    let month1 = document.getElementById("grant-month-1");
    let month2 = document.getElementById("grant-month-2");
    let month3 = document.getElementById("grant-month-3");

    return {
      month0: {
        month: month0.dataset.month,
        count: month0.dataset.grantrequestscount,
      },
      month1: {
        month: month1.dataset.month,
        count: month1.dataset.grantrequestscount,
      },
      month2: {
        month: month2.dataset.month,
        count: month2.dataset.grantrequestscount,
      },
      month3: {
        month: month3.dataset.month,
        count: month3.dataset.grantrequestscount,
      },
    };
  }
}
