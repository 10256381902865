import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["image", "reminder"]

  avatar_upload(event) {
    // change the avatar upon upload of a new avatar image and display the note to
    // remind the user to save to take effect.
    if (event.target.files.length == 1) {
      
      // swap the current avatar image with the new one
      const new_img_url = URL.createObjectURL(event.target.files[0]);
      this.imageTarget.src = null;
      this.imageTarget.src = new_img_url;

      // display the reminder
      this.reminderTarget.classList.remove('invisible')
    }
  }
}
