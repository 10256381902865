import { Controller } from "stimulus";

export default class extends Controller {

  donor_source_select(event) {
    // This function changes the available payment methods for the user depending on
    // what source user is chosen. It will also call the toggle_anonymous function.
    const donor_id = event.target.selectedOptions[0].value;
    const payment_select = document.getElementById('payment-selector');
    const allOptions = payment_select.querySelectorAll('option:not(:first-child)');
    const payment_data = JSON.parse(this.data.get('payments'))[donor_id];

    // toggle the anonymous check
    this.toggle_anonymous();

    // clear out current options by default
    allOptions.forEach(o => o.remove());

    // get users payment options from hash and create options
    if (typeof payment_data != 'undefined') {  
      payment_data.forEach(function (pd) {
        const option = document.createElement('option');
        option.value = pd[0];
        option.text = pd[1];
        payment_select.add(option);
      });
    }
  }

  toggle_anonymous() {
    // Toggles the 'make donation anonymously' checkbox on the contribution form when
    // an adivsor changes the source user to be the same as the receivable user.
    const receivable_type = document.getElementById('contribution_receivable_type').value;
    if (receivable_type == 'User') {
      const receivable_user_id = document.getElementById('contribution_receivable_id').selectedOptions[0].value;
      const source_user_id = document.getElementById('contribution_user_id').selectedOptions[0].value;
      const anonymous_div = document.getElementById('anonymous-contribution');
      const anonymous_check = anonymous_div.querySelector('#contribution_anonymous');
  
      // uncheck the box and hide the div if the source user is the same as the receivable
      if (receivable_user_id == source_user_id) {
        anonymous_div.classList.add('hidden');
        anonymous_check.checked = false;
      } else if (anonymous_div.classList.contains('hidden')) {
        anonymous_div.classList.remove('hidden');
      }
    }
  }
}
