import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["firstName", "lastName"]

  change_first_last_name(event) {
    // changes the pre-set first and last name in new stocks form if the user
    // has defined a first or last name
    const selected_donor_dataset = event.target.options[event.target.selectedIndex].dataset;

    // clear out the values of these
    this.firstNameTarget.value = '';
    this.lastNameTarget.value = '';

    // set the new first and last name
    if (selected_donor_dataset.first) {
      this.firstNameTarget.value = selected_donor_dataset.first;
    }
    if (selected_donor_dataset.last) {
      this.lastNameTarget.value = selected_donor_dataset.last;
    }
  };
}