import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener('ajax:complete', (response) => {
      setTimeout(() => {
        var container = document.getElementById('charity-photo-refresh')
        var event = new Event('refresh')
        container.dispatchEvent(event)
      }, 1500)
    })
  }
}
