import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"]

  connect() {
    let utc_expiration = this.data.get('utc');
    let local_expiration_time = new Date(utc_expiration).toLocaleTimeString('en-US', {timeZoneName: 'short'});
    let local_expiration_date = new Date(utc_expiration).toDateString();
    this.outputTarget.textContent += `${local_expiration_date} at ${local_expiration_time}` 
  }
}
