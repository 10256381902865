import { Controller } from "stimulus";

import $ from 'jquery';

require("select2/dist/css/select2");

import Select2 from "select2";

export default class extends Controller {
  connect() {
    $('#campaign_organization_ids').select2({
      multiple: true,
      tokenSeparators: [','],
      ajax: {
        url: "/charities?page=1",
        dataType: 'json',
        processResults: function (data) {
          var results = $.map(data.results, function (obj) {
            var label = obj.name || obj.text;
  
            obj['text'] = label;
            return obj;
          });
          return {
            results: results
          };
        }
      }
   });
  }
}
