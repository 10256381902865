import { Controller } from "stimulus";
import mixpanel from "mixpanel-browser";

// gets the object ID for anything from the URL path
const get_object_id = path => path.substring(window.location.pathname.lastIndexOf('/') + 1)

export default class extends Controller {
  
  MP_ENABLED = false;

  initialize() {
    // initialize mixpanel
    const mp_key_meta_tag = document.querySelector('meta[data-mixpanel-key]');
    const mp_key = mp_key_meta_tag.dataset.mixpanelKey ? mp_key_meta_tag.dataset.mixpanelKey : null;
    this.MP_ENABLED = !!mp_key;

    if (this.MP_ENABLED) {
      mixpanel.init(mp_key);
    
      const user_data = document.querySelector('meta[data-user-id]').dataset;
  
      // identiy the user by ID and apply their email to their mixpanel profile
      mixpanel.identify(user_data.email);
      mixpanel.people.set({user: user_data.userId});
    }
  }
  
  connect() {
    // tracks every page load and writes that page name to the 'page' property
    if (this.MP_ENABLED) mixpanel.track('page load', { page: document.title });
  }

  // dashboard
  dashboard_crypto_learn_more_event(event) {
    // generate a mixpanel event for the 'crypto learn more' link on dashboard
    if (this.MP_ENABLED) mixpanel.track('Crypto Learn More');
  }

  dashboard_help_event(event) {
    // generate a mixpanel event for the 'help' link on dashboard
    if (this.MP_ENABLED) mixpanel.track('dashboard help');
  }

  dashboard_buzz_event(event) {
    // generates a mixpanel event whenever a user clicks a blog in "The Buzz" 
    // on the dashboard
    if (this.MP_ENABLED) {
      const data = event.target.parentElement.closest('a').dataset;
      mixpanel.track('the buzz', { article: data.mixpanelArticle });
    }
  }

  // campaigns
  campaign_share_event(event) {
    // generates a mixpanel event whenever a Campaign share button is clicked
    if (this.MP_ENABLED) {
      const parent = event.target.parentElement.closest('a') || event.target.parentElement.closest('button');
      mixpanel.track('campaign share', { shared_to: parent.dataset.mixpanelShareTo });
    }
  }

  campaign_email_share_event(event) {
    // Generates a mixpanel event when a campaign is shared via email
    if (this.MP_ENABLED) {
      mixpanel.track('campaign email share')
    }
  }

  campaign_heart_event(event) {
    // generates a mixpanel event whenever a Campaign is 'hearted' or 'unhearted' on show
    // and index views
    if (this.MP_ENABLED) {
      const btn = event.target.parentElement.closest('button');
      const campaign_id = btn.dataset.mixpanelCampaign
      
      // hearted = true when a user toggles a heart, and hearted = false when a user unhearts a campaign
      const hearted = btn.querySelector('.text-brandalert').classList.contains('hidden');
      if (hearted) {
        mixpanel.track('campaign heart', { campaign:  campaign_id });
      } else {
        mixpanel.track('campaign unheart', { campaign:  campaign_id });
      }
    }
  }

  campaign_qr_code_saved_event(event) {
    // mixpanel event when a user saves a campaign QR code
    if (this.MP_ENABLED) {
      const campaign_id = get_object_id(window.location.pathname);
      mixpanel.track('campaign qr saved', { campaign: campaign_id });
    }
  }

  campaign_media_add_event(event) {
    // mixpanel event when a user clicks the add media button, but media hasn't been
    // uploaded yet
    if (this.MP_ENABLED) {
      const campaign_id = get_object_id(window.location.pathname);
      mixpanel.track('campaign media add clicked', { campaign: campaign_id });
    }
  }

  campaign_media_upload_event(event) {
    // mixpanel event when a user uploads media to a campaign
    if (this.MP_ENABLED) {
      const campaign_id = get_object_id(window.location.pathname);
      mixpanel.track('campaign media uploaded', { campaign: campaign_id });
    }
  }

  campaign_media_delete_event(event) {
    // mixpanel event when media is deleted from a campaign
    if (this.MP_ENABLED) {
      const campaign_id = get_object_id(window.location.pathname);
      mixpanel.track('campaign media deleted', { campaign: campaign_id });
    }
  }

  campaign_media_move_event(event) {
    // mixpanel event when media is moved up or down
    if (this.MP_ENABLED) {
      const campaign_id = get_object_id(window.location.pathname);
      const direction = event.target.parentElement.dataset.mediaMoveDirection;
      mixpanel.track("campaign media moved " + direction, { campaign: campaign_id });
    }
  }

  campaign_cta_media_add_event(event) {
    // mixpanel event when media add button is clicked
    if (this.MP_ENABLED) {
      const campaign_id = get_object_id(window.location.pathname);
      mixpanel.track('campaign cta media add clicked', { campaign: campaign_id });
    }
  }

  campaign_cta_media_upload_event(event) {
    // mixpanel event when a user uploads media to a campaign OR when a user changes
    // description on CTA media
    if (this.MP_ENABLED) {
      const media_upload = !!event.target.querySelectorAll('input')[2].value  //input[2] is the file hash
      const campaign_id = get_object_id(window.location.pathname);
      if (media_upload) {
        mixpanel.track('campaign cta media uploaded', { campaign: campaign_id });
      } else {
        mixpanel.track('campaign cta media description changed', { campaign: campaign_id });
      }
    }
  }

  campaign_cta_add_description_event(event) {
    // mixpanel event when a user clicks the add description button
    const campaign_id = get_object_id(window.location.pathname);
    mixpanel.track('campaign cta add description clicked', { campaign: campaign_id })
  }

  campaign_cta_media_delete_event(event) {
    // mixpanel event when media is deleted from a campaign
    if (this.MP_ENABLED) {
      const campaign_id = get_object_id(window.location.pathname);
      mixpanel.track('campaign cta media deleted', { campaign: campaign_id });
    }
  }

  campaign_end_event(event) {
    // mixpanel event for when a user ends a campaign
    if (this.MP_ENABLED) {
      mixpanel.track('campaign ended');
    }
  }

  campaign_history_csv_export_event(event) {
    // mixpanel event for when a user exports the transaction history of a Campaign to CSV
    if (this.MP_ENABLED) {
      mixpanel.track('campaign transaction export', { format: 'csv' });
    }
  }

  campaign_edit_charity_add_event(event) {
    // mixpanel event for when charities are added to the 
    if (this.MP_ENABLED) {
      const select_field = document.querySelector('#campaign_organization_ids');
      const initial_charity_ids = event.target.dataset.mixpanelCharityIds.split(',');
      const final_charity_ids = Array.from(select_field.options).map(option => option.value)
      const removed_charities = initial_charity_ids.filter(x => !final_charity_ids.includes(x));
      const added_charities = final_charity_ids.filter(x => !initial_charity_ids.includes(x));
      const mixpanel_event = (mp_event, id) => mixpanel.track(mp_event, { charity: id });

      // notify mixpanel of removed charities -> not working yet due to select2 issues, revisit this
      // removed_charities.forEach(charity => mixpanel_event('campaign charity removed', charity))

      // notify mixpanel of added charities
      added_charities.forEach(charity => mixpanel_event('campaign charity added', charity));
    }
  }

  // contributions
  contribution_quick_select_amount_event(event) {
    // mixpanel event when a user clicks a quick select amount in the contribution form
    if (this.MP_ENABLED) {
      const amount = event.target.dataset.amount;
      mixpanel.track('contribution quick select amount', { amount: amount});
    }
  }

  contribution_set_tip_event(event) {
    // mixpanel event when a user clicks a tip percentage button in the contribution form
    if (this.MP_ENABLED) {
      const amount = event.target.dataset.amount > 0 ? event.target.dataset.amount : 'other';
      mixpanel.track('contribution set tip', { amount: amount});
    }
  }

  contribution_add_new_payment_type_event(event) {
    // mixpanel event when a user sets a new payment type
    if (this.MP_ENABLED) {
      const type = event.target[event.target.selectedIndex].value;
      mixpanel.track('contribution new payment method', { method: type });
    }
  }

  contribution_e_commerce_conversion_event(event) {
    // Mixpanel event generated when contribution form is submitted
    if (this.MP_ENABLED) {
      const contribution_amt = document.querySelector('#contribution_amount').value
      const tip_amt = document.querySelector('#contribution_tip_amount').value
      const tip_multiplier = document.querySelector('#contribution_tip_multiplier').value
      const contribution_total = document.querySelector('#total').value
      mixpanel.track('Ecommerce conversion', {
        contribution_amount: contribution_amt,
        tip_amount: tip_amt,
        tip_multiplier: tip_multiplier,
        contribution_total: contribution_total
      })
    }
  }

  contribution_pdf_download_event(event) {
    // Mixpanel event generated when a user views a receipt as a PDF
    if (this.MP_ENABLED) {
      const contribution_id = event.target.pathname.split('/')[2];
      mixpanel.track('contribution pdf', {contribution: contribution_id});
    }
  }

  contribution_share_event(event) {
    // Mixpanel event generated when a user shares a contribution
    if (this.MP_ENABLED) {
      const contribution_id = window.location.pathname.split('/')[2];
      const parent = event.target.parentElement.closest('a') || event.target.parentElement.closest('button');
      mixpanel.track('contribution share', { shared_to: parent.dataset.mixpanelShareTo, contribution: contribution_id });
    }
  }

  // Matches
  match_request_form_event(event) {
    // Mixpanel event generated when an employer match form is submitted
    if (this.MP_ENABLED) {
      const new_or_updated = window.location.pathname.includes('edit') ? 'updated' : 'submitted';
      const match_email = document.querySelector('#match_email').value;
      const default_message_used = document.querySelector('#match_use_default_messaging').checked;
      const receipt_included = document.querySelector('#match_include_receipt').checked;
      const mixpanel_event = `contribution match form ${new_or_updated}`;
      mixpanel.track(mixpanel_event, {
        match_email: match_email,
        default_message_used: default_message_used,
        receipt_included: receipt_included
      });
    }
  }

  match_thank_you_event(event) {
    // Mixpanel event generated when an employer clicks 'Contribute' on the Thank You page
    mixpanel.track('Employer match thank you', { contribution: event.target.dataset.mixpanelContribution })
  }

  // Navbar events
  nav_bar_sign_out_event(event) {
    // mixpanel event generated when user clicks 'sign out'
    mixpanel.track('Sign Out', {page: document.title})
  }

  nav_bar_profile_menu_toggle_event(event) {
    // Mixpanel event generated when user clicks on the navbar profile menu
    mixpanel.track('Navbar menu toggle')
  }
}
