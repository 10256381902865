import { Controller } from "stimulus";
import { track } from "../analytics";

export default class extends Controller {
  static targets = [
    "add",
    "save",
    "cancel",
    "remove",
    "cardElement",
    "cardErrors",
    "cardWrapper",
    "cardList",
  ];

  remove(event) {
    event.preventDefault();
    this.removeTarget.disabled = true;
    this.removeTarget.textContent = "Removing...";

    fetch("/profile/remove_payment_method", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify({
        payment_method_id: event.target.dataset.paymentMethodId,
      }),
    })
      .then((response) => response.json())
      .then(({ payment_methods }) => {
        this.cardListTarget.innerHTML = payment_methods;
      });
  }

  addNew(event) {
    event && event.preventDefault();
    this.cardWrapperTarget.classList.remove("hidden");
    this.addTarget.classList.add("hidden");
    this.cancelTarget.classList.remove("hidden");
    this.saveTarget.classList.remove("hidden");
  }

  hideNew(event) {
    event && event.preventDefault();
    this.addTarget.classList.remove("hidden");
    this.saveTarget.classList.add("hidden");
    this.cancelTarget.classList.add("hidden");
    this.cardWrapperTarget.classList.add("hidden");
  }

  hideCancel() {
    this.cancelTarget.classList.add("hidden");
  }

  showCancel(event) {
    this.cancelTarget.classList.remove("hidden");
  }

  saveCard(event) {
    this.saveTarget.disabled = true;
    this.saveTarget.textContent = "Saving card...";
    this.hideCancel();
    event.preventDefault();

    track("AddPaymentInfo");

    let paymentIntent;

    fetch("/contributions/setup_payment_intent")
      .then((response) => response.json())
      .then(({ token }) => {
        this.stripe
          .confirmCardSetup(token, { payment_method: { card: this.card } })
          .then((result) => {
            if (result.error) {
              let errorElement = this.cardErrorsTarget;
              errorElement.textContent = result.errormessage;
            } else {
              fetch("/profile/fetch_payment_methods")
                .then((response) => response.json())
                .then(({ payment_methods }) => {
                  this.hideNew();
                  this.saveTarget.disabled = false;
                  this.saveTarget.textContent = "Save Card";
                  this.cardListTarget.innerHTML = payment_methods;
                });
            }
          });
      });
  }

  initialize() {
    this.stripe = Stripe(this.data.get("key"));
    this.elements = this.stripe.elements();
    this.style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    this.card = this.elements.create("card", {
      style: this.style,
    });

    this.card.mount(this.cardElementTarget);
  }
}
