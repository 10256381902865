import { Controller } from "stimulus";
import { GoogleCharts } from "google-charts";
import { track } from "../analytics";
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../stylesheets/tailwind.config';

const fullConfig = resolveConfig(tailwindConfig)

export default class extends Controller {
  connect() {
    if (this.data.get("complete-registration") === "true") {
      track("CompleteRegistration");
    }
    GoogleCharts.load(this.drawChart.bind(this));
  }

  trackContribute() {
    track("InitiateCheckout")
  }

  drawChart() {
    let balanceData = this.getBalanceData();
    let userContributionData = this.getUserContributionData();
    let campaignContributionData = this.getCampaignContributionData();
    let grantRequestData = this.getGrantRequestData();

    this.createBalanceAmountChart(balanceData);
    this.createUserContributionsChart(userContributionData);
    this.createCampaignContributionsChart(campaignContributionData);
    this.createGrantRequestAmountChart(grantRequestData);
  }

  // CHART 1 - Monthly Balance
  createBalanceAmountChart(data) {
    let balanceChartData = google.visualization.arrayToDataTable([
      ["Month", "Balance"],
      [data.month3.month, parseFloat(data.month3.balance)],
      [data.month2.month, parseFloat(data.month2.balance)],
      [data.month1.month, parseFloat(data.month1.balance)],
      [data.month0.month, parseFloat(data.month0.balance)],
    ]);

    let formatter = new google.visualization.NumberFormat({
      prefix: "$",
    });
    formatter.format(balanceChartData, 1);

    let colors = fullConfig.theme.colors;
    let brandprimary = colors.brandprimary.DEFAULT
    let brandgray = colors.brandgray.DEFAULT

    let options = {
      colors: [brandprimary],
      vAxis: {
        gridlines: {
          color: 'transparent'
        }
      },
      legend: {
        position: "none",
      },
    };

    let chart = new google.visualization.AreaChart(
      document.getElementById("balance-amount")
    );

    chart.draw(balanceChartData, options);
  }

  // CHART 2 - Monthly Contributions
  createUserContributionsChart(data) {
    let userContributionChartData = google.visualization.arrayToDataTable([
      ["Year", "Contributions"],
      [data.month3.month, parseFloat(data.month3.userContributions)],
      [data.month2.month, parseFloat(data.month2.userContributions)],
      [data.month1.month, parseFloat(data.month1.userContributions)],
      [data.month0.month, parseFloat(data.month0.userContributions)],
    ]);

    let formatter = new google.visualization.NumberFormat({
      prefix: "$",
    });
    formatter.format(userContributionChartData, 1);

    let colors = fullConfig.theme.colors;
    let brandprimary = colors.brandprimary.DEFAULT
    let brandgray = colors.brandgray.DEFAULT

    let options = {
      colors: [brandprimary],
      vAxis: {
        gridlines: {
          color: 'transparent'
        }
      },
      legend: {
        position: "none",
      },
    };

    let chart = new google.visualization.AreaChart(
      document.getElementById("user-contribution-amount")
    );
    chart.draw(userContributionChartData, options);
  }

  // CHART 3 - Monthly Campaign Contributions
  createCampaignContributionsChart(data) {
    let campaignContributionChartData = google.visualization.arrayToDataTable([
      ["Year", "Contributions"],
      [data.month3.month, parseFloat(data.month3.campaignContributions)],
      [data.month2.month, parseFloat(data.month2.campaignContributions)],
      [data.month1.month, parseFloat(data.month1.campaignContributions)],
      [data.month0.month, parseFloat(data.month0.campaignContributions)],
    ]);

    let formatter = new google.visualization.NumberFormat({
      prefix: "$",
    });
    formatter.format(campaignContributionChartData, 1);

    let colors = fullConfig.theme.colors;
    let brandprimary = colors.brandprimary.DEFAULT
    let brandgray = colors.brandgray.DEFAULT

    let options = {
      colors: [brandprimary],
      vAxis: {
        gridlines: {
          color: 'transparent'
        }
      },
      legend: {
        position: "none",
      },
    };

    let chart = new google.visualization.AreaChart(
      document.getElementById("campaign-contribution-amount")
    );
    chart.draw(campaignContributionChartData, options);
  }

  // CHART 3 - Monthly Grant Request Count
  createGrantRequestAmountChart(data) {
    let grantRequestCountData = google.visualization.arrayToDataTable([
      ["Year", "Grant Requests"],
      [data.month3.month, parseFloat(data.month3.grantAmount)],
      [data.month2.month, parseFloat(data.month2.grantAmount)],
      [data.month1.month, parseFloat(data.month1.grantAmount)],
      [data.month0.month, parseFloat(data.month0.grantAmount)],
    ]);

    let formatter = new google.visualization.NumberFormat({
      prefix: "$",
    });
    formatter.format(grantRequestCountData, 1);

    let colors = fullConfig.theme.colors;
    let brandprimary = colors.brandprimary.DEFAULT
    let brandgray = colors.brandgray.DEFAULT

    let options = {
      colors: [brandprimary],
      vAxis: {
        gridlines: {
          color: 'transparent'
        }
      },
      legend: {
        position: "none",
      },
    };

    let chart = new google.visualization.AreaChart(
      document.getElementById("grant-requests-amount")
    );
    chart.draw(grantRequestCountData, options);
  }

  /*
   * Retrieving data from html data attributes
   */

  // DATA - Balance Data
  getBalanceData() {
    let month0 = document.getElementById("month-0");
    let month1 = document.getElementById("month-1");
    let month2 = document.getElementById("month-2");
    let month3 = document.getElementById("month-3");
    return {
      month0: {
        month: month0.dataset.month,
        balance: month0.dataset.balance,
      },
      month1: {
        month: month1.dataset.month,
        balance: month1.dataset.balance,
      },
      month2: {
        month: month2.dataset.month,
        balance: month2.dataset.balance,
      },
      month3: {
        month: month3.dataset.month,
        balance: month3.dataset.balance,
      },
    };
  }

  // DATA - Contributions
  getUserContributionData() {
    let month0 = document.getElementById("user-contribution-month-0");
    let month1 = document.getElementById("user-contribution-month-1");
    let month2 = document.getElementById("user-contribution-month-2");
    let month3 = document.getElementById("user-contribution-month-3");

    return {
      month0: {
        month: month0.dataset.month,
        userContributions: month0.dataset.usercontributions,
      },
      month1: {
        month: month1.dataset.month,
        userContributions: month1.dataset.usercontributions,
      },
      month2: {
        month: month2.dataset.month,
        userContributions: month2.dataset.usercontributions,
      },
      month3: {
        month: month3.dataset.month,
        userContributions: month3.dataset.usercontributions,
      },
    };
  }

  // DATA - Contributions
  getCampaignContributionData() {
    let month0 = document.getElementById("campaign-contribution-month-0");
    let month1 = document.getElementById("campaign-contribution-month-1");
    let month2 = document.getElementById("campaign-contribution-month-2");
    let month3 = document.getElementById("campaign-contribution-month-3");

    return {
      month0: {
        month: month0.dataset.month,
        campaignContributions: month0.dataset.campaigncontributions,
      },
      month1: {
        month: month1.dataset.month,
        campaignContributions: month1.dataset.campaigncontributions,
      },
      month2: {
        month: month2.dataset.month,
        campaignContributions: month2.dataset.campaigncontributions,
      },
      month3: {
        month: month3.dataset.month,
        campaignContributions: month3.dataset.campaigncontributions,
      },
    };
  }

  // DATA - Grant Requests
  getGrantRequestData() {
    let month0 = document.getElementById("grant-request-month-0");
    let month1 = document.getElementById("grant-request-month-1");
    let month2 = document.getElementById("grant-request-month-2");
    let month3 = document.getElementById("grant-request-month-3");

    return {
      month0: {
        month: month0.dataset.month,
        grantAmount: month0.dataset.grantamount,
      },
      month1: {
        month: month1.dataset.month,
        grantAmount: month1.dataset.grantamount,
      },
      month2: {
        month: month2.dataset.month,
        grantAmount: month2.dataset.grantamount,
      },
      month3: {
        month: month3.dataset.month,
        grantAmount: month3.dataset.grantamount,
      },
    };
  }
}
