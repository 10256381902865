import { Controller } from "stimulus";
function round(number) {
  return Math.round(number * 100.0) / 100.0;
}
export default class extends Controller {
  static targets = [
    "tipAmount",
    "tipMultiplier"
  ];

  // we keep the calculated tip here as a number and set it manually after each action
  // the reason is to prevent the tip input to be formated until the user finish editing
  // the field
  totalTip = 0;

  setTip(event) {
    event.preventDefault();
    this.updateSelectedElement(".tip-action", event);
    let tipAmount = event.target.dataset.amount;
    this.hideShowOtherTipField(tipAmount);
    this.tipAmountTarget.value = this.totalTip.toFixed(2);
  }

  updateSelectedElement(klassToSearch, event) {
    this.deselectElements(klassToSearch);
    event.target.classList.remove("bg-white", "text-brandprimary");
    event.target.classList.add("bg-brandprimary", "text-white");
  }

  deselectElements(klassToSearch) {
    const elements = document.querySelectorAll(klassToSearch);

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("bg-brandprimary", "text-white");
      elements[i].classList.add("bg-white", "text-brandprimary");
    }
  }

  getTipAmount() {
    return parseFloat(this.tipAmountTarget.value, 10) || 0;
  }

  getTipMultiplier() {
    return parseFloat(this.tipMultiplierTarget.value, 10) || 0;
  }

  setDefaultSelectedTip() {
    const tipAction = document.querySelectorAll(".tip-action");
    const value = this.tipMultiplierTarget.value;

    tipAction.forEach((tip) => {
      const formattedAmount = parseFloat(tip.dataset.amount, 10) || 0;
      if (formattedAmount == value) {
        tip.classList.remove("bg-white", "text-brandprimary");
        tip.classList.add("bg-brandprimary", "text-white");
      }
    });
  }

  hideShowOtherTipField(tipMultiplier) {
    this.tipMultiplierTarget.value = tipMultiplier;
    if (tipMultiplier === "0") {
      this.tipAmountTarget.parentElement.classList.remove("hidden");
    } else {
      this.tipAmountTarget.parentElement.classList.add("hidden");
    }
  }

  initialize() {
    this.tipAmountTarget.addEventListener("blur", (e) => {
      this.tipAmountTarget.value = (
        parseFloat(e.target.value, 10) || 0
      ).toFixed(2);
    });

    this.setDefaultSelectedTip();
    this.hideShowOtherTipField(`${this.getTipMultiplier()}`)
  }
}
