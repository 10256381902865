import { Controller } from "stimulus";
import { track } from "../analytics";
import AchManager from "../helpers/ach_manager";

function round(number) {
  return Math.round(number * 100.0) / 100.0;
}
export default class extends Controller {
  static targets = [
    "cardElement",
    "newPaymentType",
    "cardErrors",
    "form",
    "reviewInput",
    "reviewButton",
    "amount",
    "total",
    "tipAmount",
    "tipMultiplier",
    "savePaymentMethod",
    "savePaymentMethodInput",
    "paymentSelector",
  ];

  static values = { calculateTax: Boolean };
  // we are using this value to select the calculation direction
  // if the last used is amount, then we are going to calculate the change
  // of tips based on the amount otherwise from the total
  lastUsed = "amount";
  processingFees = 0;
  // we keep the calculated tip here as a number and set it manually after each action
  // the reason is to prevent the tip input to be formated until the user finish editing
  // the field
  totalTip = 0;
  setAmount(event) {
    event.preventDefault();
    let amount = parseFloat(event.target.dataset.amount, 10) || 0;
    this.amountTarget.value = amount.toFixed(2);
    this.doSetAmount(amount);
    this.setSelectedQuickAction(amount);
    this.tipAmountTarget.value = this.totalTip.toFixed(2);
    this.lastUsed = "amount";
  }
  // we must round the number after each calculation process to prevent
  // any rounding error from the last value. there is an issue with this
  // implementation we the reverse might not work. So we should save every
  // calculated field as final version and we should not change these values
  // in the backend

  validateContributionTotalAmount() {
    let amountAndAccount

    // Obtain Pay From element
    let amountAvailableElement = document.getElementById("payment-selector");
    // Get the selected account/balance
    amountAndAccount =
      amountAvailableElement.options[amountAvailableElement.selectedIndex].innerHTML;
    // Get the balance
    let amountWithDecimal = amountAndAccount.split("-")[1].trim();

    // Remove dollar sign and comma from the amount
    let userBalanceString = amountWithDecimal.replace(/,|\$/g, "");
    // let amountChosenString = document.getElementById("contribution_amount").value;
    let totalAmountString = document.getElementById("total").value;

    let maxUserBalanceString = parseFloat(userBalanceString);
    let total = parseFloat(totalAmountString);


    if (Number(total) > Number(maxUserBalanceString)) {
      this.disableReview();
    } else {
      this.enableReview();
    }
  }

  disableReview() {
    let button = this.reviewButtonTarget;
    let input = this.reviewInputTarget;
    let totalAmountWarning = document.getElementById("total-warning");

    if (button.disabled === false) {
      button.disabled = true;
      input.disabled = true;
      button.classList.add("text-brandalert");
      button.classList.remove("text-white");

      button.classList.add("cursor-not-allowed");
      button.classList.remove("cursor-pointer");

      totalAmountWarning.hidden = false;
    }
  }

  enableReview() {
    let button = this.reviewButtonTarget;
    let input = this.reviewInputTarget;
    let totalWarning = document.getElementById("total-warning");

    if (button.disabled === true) {
      button.disabled = false;
      input.disabled = false;
      button.classList.remove("text-brandalert");
      button.classList.add("text-white");

      button.classList.remove("cursor-not-allowed");
      button.classList.add("cursor-pointer");

      totalWarning.hidden = true;
    }
  }
  doSetTotalAmount(amount) {
    // deduct the processing fees from the total amount
    if (this.calculateTaxValue) {
      this.processingFees =
        amount - round(amount / (1 + this.getProcessingFee()));
      amount -= this.processingFees;
    } else {
      this.processingFees = 0;
    }
    // deduct the tip amount
    const tipMultiplier = parseFloat(this.tipMultiplierTarget.value, 10) || 0;
    let tip;
    if (tipMultiplier > 0) {
      tip = amount - round(amount / (1 + tipMultiplier));
    } else {
      tip = this.getTipAmount();
    }
    amount -= tip;
    this.totalTip = tip;
    this.amountTarget.value = amount.toFixed(2);
  }

  doSetAmount(amount) {
    // add the tip amount
    const tipMultiplier = parseFloat(this.tipMultiplierTarget.value, 10) || 0;
    let tip
    if (tipMultiplier > 0) {
      tip = round(amount * tipMultiplier);
    } else {
      tip = this.getTipAmount();
    }
    amount += tip;
    this.totalTip = tip;
    // add the processing fees from the total amount
    if (this.calculateTaxValue) {
      this.processingFees = round(amount * this.getProcessingFee());
      amount += this.processingFees;
    } else {
      this.processingFees = 0;
    }
    this.totalTarget.value = amount.toFixed(2);
    if (this.paymentSelectorTarget.value.includes('Account::')) {
      this.validateContributionTotalAmount();
    }
  }

  setTip(event) {
    event.preventDefault();
    this.updateSelectedElement(".tip-action", event);
    let tipAmount = event.target.dataset.amount;
    this.hideShowOtherTipField(tipAmount);
    this.updateTotal();
    this.tipAmountTarget.value = this.totalTip.toFixed(2);
  }

  updateSelectedElement(klassToSearch, event) {
    this.deselectElements(klassToSearch);
    event.target.classList.remove("bg-white", "text-brandfinance");
    event.target.classList.add("bg-brandfinance", "text-white");
  }

  deselectElements(klassToSearch) {
    const elements = document.querySelectorAll(klassToSearch);

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("bg-brandfinance", "text-white");
      elements[i].classList.add("bg-white", "text-brandfinance");
    }
  }

  updateTotal() {
    if (this.lastUsed === "amount") {
      this.doSetAmount(parseFloat(this.amountTarget.value, 10) || 0);
    } else {
      this.doSetTotalAmount(parseFloat(this.totalTarget.value, 10) || 0);
    }
    if (this.paymentSelectorTarget.value.includes('Account::')) {
      this.validateContributionTotalAmount();
    }
  }

  calculateTipAsPercentage() {
    return parseInt(this.tipMultiplierTarget.value * 100);
  }

  getAmount() {
    return parseFloat(this.amountTarget.value, 10) || 0;
  }

  getTipAmount() {
    return parseFloat(this.tipAmountTarget.value, 10) || 0;
  }

  getProcessingFee() {
    return parseFloat(this.data.get("processing-fee"), 10) || 0;
  }

  calculateProcessingFee() {
    return (this.getAmount() + this.getTipAmount()) * this.getProcessingFee();
  }

  setSelectedQuickAction(value) {
    const quickActions = document.querySelectorAll(".amount-action");
    quickActions.forEach((action) => {
      const formattedAmount = parseFloat(action.dataset.amount, 10) || 0;
      action.classList.remove(
        "bg-white",
        "text-brandfinance",
        "bg-brandfinance",
        "text-white"
      );
      if (formattedAmount == value) {
        action.classList.add("bg-brandfinance", "text-white");
      } else {
        action.classList.add("bg-white", "text-brandfinance");
      }
    });
  }

  setDefaultSelectedTip() {
    const tipAction = document.querySelectorAll(".tip-action");
    const value = this.tipMultiplierTarget.value;

    this.tipAmountTarget.value = this.totalTip.toFixed(2);
    tipAction.forEach((tip) => {
      const formattedAmount = parseFloat(tip.dataset.amount, 10) || 0;
      if (formattedAmount == value) {
        tip.classList.remove("bg-white", "text-brandfinance");
        tip.classList.add("bg-brandfinance", "text-white");
      }
    });
  }

  hideShowOtherTipField(tipAmount) {
    this.tipMultiplierTarget.value = tipAmount;
    if (tipAmount == "0") {
      this.tipAmountTarget.parentElement.classList.remove("hidden");
    } else {
      this.tipAmountTarget.parentElement.classList.add("hidden");
    }
  }

  connect() {
    var stripe = Stripe(this.data.get("key"));
    var elements = stripe.elements();
    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    var card = elements.create("card", {
      style: style,
    });

    card.mount(this.cardElementTarget);

    // Handle real-time validation errors from the card Element.
    let controller = this;

    card.addEventListener("change", (event) => {
      let displayError = controller.cardErrorsTarget;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });

    this.tipAmountTarget.addEventListener("keyup", (e) => {
      this.updateTotal();
    });

    this.totalTarget.addEventListener("keyup", (e) => {
      this.deselectElements(".amount-action");
      this.doSetTotalAmount(parseFloat(e.target.value, 10) || 0);
      this.tipAmountTarget.value = this.totalTip.toFixed(2);
    });

    this.totalTarget.addEventListener("blur", (e) => {
      this.totalTarget.value = parseFloat(e.target.value, 10).toFixed(2);
      this.tipAmountTarget.value = this.totalTip.toFixed(2);
      this.setSelectedQuickAction(parseFloat(this.amountTarget.value, 10));
      this.lastUsed = "total";
    });

    this.amountTarget.addEventListener("keyup", (e) => {
      this.deselectElements(".amount-action");
      this.doSetAmount(parseFloat(e.target.value, 10) || 0);
      this.tipAmountTarget.value = this.totalTip.toFixed(2);
    });

    this.amountTarget.addEventListener("blur", (e) => {
      this.amountTarget.value = parseFloat(e.target.value, 10).toFixed(2);
      this.tipAmountTarget.value = this.totalTip.toFixed(2);
      this.setSelectedQuickAction(parseFloat(this.amountTarget.value, 10));
      this.lastUsed = "amount";
    });

    this.tipAmountTarget.addEventListener("blur", (e) => {
      this.tipAmountTarget.value = (
        parseFloat(e.target.value, 10) || 0
      ).toFixed(2);
    });

    this.paymentSelectorTarget.addEventListener("change", (e) => {
      const paymentMethod = e.target.value;
      this.enableReview();
      if (paymentMethod == "new") {
        this.calculateTaxValue = true;
        this.cardElementTarget.classList.remove("hidden");
        this.newPaymentTypeTarget.classList.remove("hidden");
        this.newPaymentTypeTarget.value = "new-credit-card"
        this.savePaymentMethodTarget.classList.remove("hidden");
        this.savePaymentMethodInputTarget.checked = false;
      } else if (this.paymentSelectorTarget.value.includes('Account::')) {
        this.validateContributionTotalAmount();
      } else {
        this.calculateTaxValue = this.paymentSelectorTarget.value.includes(
          "Card::"
        );
        this.cardElementTarget.classList.add("hidden");
        if (this.hasNewPaymentTypeTarget) this.newPaymentTypeTarget.classList.add("hidden");
        this.savePaymentMethodTarget.classList.add("hidden");
        this.savePaymentMethodInputTarget.checked = true;
      }
    });
    const parsedAmount = Number(this.amountTarget.value);
    this.doSetAmount(parsedAmount);
    if ([25, 50, 100, 500].includes(parsedAmount)) {
      this.setSelectedQuickAction(parsedAmount);
    }
    this.updateTotal();

    if (this.hasNewPaymentTypeTarget) {
      this.newPaymentTypeTarget.addEventListener("change", (event) => {
        event && event.preventDefault();

        if (this.newPaymentTypeTarget.value == 'new-bank-account') {
          let achManager = new AchManager();
  
          this.cardElementTarget.classList.add("hidden");
          this.savePaymentMethodTarget.classList.add("hidden");
  
          achManager.addNew({
            accessToken: event.target.dataset.accessToken,
            onSuccess: onPlaidSuccess.bind(this),
            onExit: onPlaidCancelation.bind(this)
          });
        } else if (this.newPaymentTypeTarget.value == 'new-credit-card') {
          this.cardElementTarget.classList.remove("hidden");
        }
      });
    }

    function onPlaidCancelation() {
      let event = new Event('change');
      this.newPaymentTypeTarget.value = 'new-credit-card';
      this.savePaymentMethodTarget.classList.remove("hidden");
      this.newPaymentTypeTarget.dispatchEvent(event);
    }

    function onPlaidSuccess(json) {
      const newBankAccountJson = json['new_bank_account']
      const optionValue = newBankAccountJson["option_value"]
      const optionName = newBankAccountJson["option_name"]
      this.paymentSelectorTarget.innerHTML += `<option value="${optionValue}">${optionName}</option>`;
      this.newPaymentTypeTarget.classList.add("hidden");
      this.paymentSelectorTarget.value = optionValue;
    }

    // Handle form submission.
    this.formTarget.addEventListener("submit", (event) => {
      event.preventDefault();

      let data;

      // set the correct source user
      const source_user_input = event.target.querySelector('#contribution_user_id');
      const source_user_id = source_user_input ? source_user_input.value : null

      // If the selected payment is an Account, we don't need to invoke stripe code
      if (this.paymentSelectorTarget.value.includes("PendingAccount::")) {
        this.handler(this.paymentSelectorTarget.value.substring(16), "pending_ach_account");
      } else if (this.paymentSelectorTarget.value.includes("Account::")) {
        this.handler(this.paymentSelectorTarget.value, "account_id");
      } else if (this.paymentSelectorTarget.value.includes("Bank::")) {
        this.handler(this.paymentSelectorTarget.value.substring(6), "stripe_bank_account_id")
      } else {
        if (this.paymentSelectorTarget.value.includes("Card::")) {
          const pm = this.paymentSelectorTarget.value.substring(6);
          data = { payment_method: pm };
        } else {
          data = { payment_method: { card: card } };
        }
 
        fetch(`/contributions/setup_payment_intent?source_id=${source_user_id}`)
          .then((response) => response.json())
          .then(({ token }) => {
            stripe.confirmCardSetup(token, data).then((result) => {
              if (result.error) {
                let errorElement = controller.cardErrorsTarget;
                errorElement.textContent = result.error.message;
              } else {
                this.handler(result.setupIntent.id, "stripe_token_id");
                track("Donate");
              }
            });
          });
      }
    });

    this.setDefaultSelectedTip();
  }
  // Submit the form with the token or account id
  handler(token, field) {
    // Insert the token ID into the form so it gets submitted to the server
    var form = this.formTarget;
    var hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", `contribution[metadata][${field}]`);
    hiddenInput.setAttribute("value", token);

    // Insert the payment_method ID so that we can select it, if edit is needed
    let hiddenPaymentSelection = document.createElement("input");
    hiddenPaymentSelection.setAttribute("type", "hidden");
    hiddenPaymentSelection.setAttribute("name", `contribution[metadata][payment_selection]`);
    hiddenPaymentSelection.setAttribute("value", this.paymentSelectorTarget.value);

    form.appendChild(hiddenInput);
    form.appendChild(hiddenPaymentSelection);

    // Submit the form
    form.submit();
  }
}
