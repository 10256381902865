import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];
  initialize() {
    this.setupRecurring();
  }
  setupRecurring() {
    var check = this.element.querySelector("#recurring");
    if (check) {
      check.addEventListener("change", (event) => {
        this.toggleRecurringPanel(event.target.checked);
      });
    }
    var frequency = this.element.querySelector(
      "#contribution_subscription_attributes_frequency"
    );
    if (frequency) {
      frequency.addEventListener("change", (event) => {
        this.showSelectedFrequencySelector(event.target.value);
      });
      this.showSelectedFrequencySelector(frequency.value);
    }
  }
  toggleRecurringPanel(visible) {
    var panel = this.element.querySelector("#recurring-panel");
    this.toggle(panel, visible);
  }
  toggle(el, visible) {
    if (visible) {
      el.classList.remove("hidden");
    } else {
      el.classList.add("hidden");
    }
  }
  showSelectedFrequencySelector(selected) {
    let month;
    let week;
    switch (selected) {
      case "monthly":
        month = true;
        week = false;
        break;
      case "biweekly":
      case "weekly":
        month = false;
        week = true;
        break;
      default:
        month = false;
        week = false;
    }
    this.toggle(this.element.querySelector("#frequency-month-selector"), month);
    this.toggle(this.element.querySelector("#frequency-week-selector"), week);
  }
}
