import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const thumbnailContainer = document.querySelector('.thumbnail-list-inner-container')
    if (thumbnailContainer) {
      this.setDraggableSlider()
    }
    document.addEventListener('ajax:complete', (response) => {
      setTimeout(() => {
        var container = document.getElementById('gallery-refresh')
        var event = new Event('refresh')
        container.dispatchEvent(event)
      }, 1500)
    })
  }

  setDraggableSlider() {
    const slider = document.querySelector('.thumbnail-list-inner-container');
    let isDown = false;
    let startX;
    let scrollLeft;

    /*
      credit Ionut Daniel: Horizontal Click and Drag Scrolling with JS
      https://codepen.io/thenutz/pen/VwYeYEE
    */

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    })
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    })
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    })
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3;
      slider.scrollLeft = scrollLeft - walk;
    })
  }

  scrollThumbnail(event) {
    if (event.target.className.includes('angle-right')) event.target.offsetParent.querySelector('.thumbnail-list-inner-container').scrollLeft += 20
    else event.target.offsetParent.querySelector('.thumbnail-list-inner-container').scrollLeft -= 20
  }
}
