import { Controller } from "stimulus";
import $ from "jquery";
import { debounce } from '../helpers'

function round(number) {
  return Math.round(number * 100.0) / 100.0;
}
export default class extends Controller {
  static targets = [
    "grantAmount",
    "selectPayFrom",
    "predefinedSource",
    "cardErrors",
    "form",
    "amount",
    "total",
    "tipAmount",
    "tipMultiplier",
  ];
  fetchOrganizations(params) {
    const formatState = this.formatState
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: '/charities',
      data: {
        q: params.term
      }
    }).done((data) => {
      const searchResults = data.results.map(function (org) {
        return { ...org, text: org.name };
      })
      const nodes = this.getNodes(searchResults)
      formatState(nodes)
    })
  }

  lastUsed = "amount";
  totalTip = 0;

  doSetTotalAmount(amount) {
    const tipMultiplier = parseFloat(this.tipMultiplierTarget.value, 10) || 0;
    let tip;
    if (tipMultiplier > 0) {
      tip = amount - round(amount / (1 + tipMultiplier));
    } else {
      tip = this.getTipAmount();
    }
    amount -= tip;
    this.totalTip = tip;
    this.amountTarget.value = amount.toFixed(2);
  }

  doSetAmount(amount) {
    // add the tip amount
    const tipMultiplier = parseFloat(this.tipMultiplierTarget.value, 10) || 0;
    let tip
    if (tipMultiplier > 0) {
      tip = round(amount * tipMultiplier);
    } else {
      tip = this.getTipAmount();
    }
    amount += tip;
    this.totalTip = tip;
    this.totalTarget.value = amount.toFixed(2);
  }

  setTip(event) {
    event.preventDefault();
    this.updateSelectedElement(".tip-action", event);
    let tipAmount = event.target.dataset.amount;
    this.hideShowOtherTipField(tipAmount);
    this.updateTotal();
    this.tipAmountTarget.value = this.totalTip.toFixed(2);
    this.validateGrantRequestTotalAmount();
  }

  updateSelectedElement(klassToSearch, event) {
    this.deselectElements(klassToSearch);
    event.target.classList.remove("bg-white", "text-brandfinance");
    event.target.classList.add("bg-brandfinance", "text-white");
  }

  deselectElements(klassToSearch) {
    const elements = document.querySelectorAll(klassToSearch);

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("bg-brandfinance", "text-white");
      elements[i].classList.add("bg-white", "text-brandfinance");
    }
  }

  updateTotal() {
    if (this.lastUsed === "amount") {
      this.doSetAmount(parseFloat(this.amountTarget.value, 10) || 0);
    } else {
      this.doSetTotalAmount(parseFloat(this.totalTarget.value, 10) || 0);
    }
  }

  calculateTipAsPercentage() {
    return parseInt(this.tipMultiplierTarget.value * 100);
  }

  getAmount() {
    return parseFloat(this.amountTarget.value, 10) || 0;
  }

  getTipAmount() {
    return parseFloat(this.tipAmountTarget.value, 10) || 0;
  }

  setSelectedQuickAction(value) {
    const quickActions = document.querySelectorAll(".amount-action");
    quickActions.forEach((action) => {
      const formattedAmount = parseFloat(action.dataset.amount, 10) || 0;
      action.classList.remove(
        "bg-white",
        "text-brandfinance",
        "bg-brandfinance",
        "text-white"
      );
      if (formattedAmount == value) {
        action.classList.add("bg-brandfinance", "text-white");
      } else {
        action.classList.add("bg-white", "text-brandfinance");
      }
    });
  }

  setDefaultSelectedTip() {
    const tipAction = document.querySelectorAll(".tip-action");
    const value = this.tipMultiplierTarget.value;

    tipAction.forEach((tip) => {
      const formattedAmount = parseFloat(tip.dataset.amount, 10) || 0;
      if (formattedAmount == value) {
        tip.classList.remove("bg-white", "text-brandfinance");
        tip.classList.add("bg-brandfinance", "text-white");
      }
    });
  }

  hideShowOtherTipField(tipAmount) {
    this.tipMultiplierTarget.value = tipAmount;
    if (tipAmount == "0") {
      this.tipAmountTarget.parentElement.classList.remove("hidden");
    } else {
      this.tipAmountTarget.parentElement.classList.add("hidden");
    }
  }

  initialize() {
    this.tipAmountTarget.addEventListener("keyup", (e) => {
      this.updateTotal();
    });

    this.tipMultiplierTarget.addEventListener("keyup", (e) => {
      this.updateTotal();
    });
    this.totalTarget.addEventListener("keyup", (e) => {
      this.deselectElements(".amount-action");
      this.doSetTotalAmount(parseFloat(e.target.value, 10) || 0);
      this.tipAmountTarget.value = this.totalTip.toFixed(2);
    });

    this.totalTarget.addEventListener("blur", (e) => {
      this.totalTarget.value = parseFloat(e.target.value, 10).toFixed(2);
      this.tipAmountTarget.value = this.totalTip.toFixed(2);
      this.setSelectedQuickAction(parseFloat(this.amountTarget.value, 10));
      this.lastUsed = "total";
    });

    this.amountTarget.addEventListener("keyup", (e) => {
      this.deselectElements(".amount-action");
      this.doSetAmount(parseFloat(e.target.value, 10) || 0);
      this.tipAmountTarget.value = this.totalTip.toFixed(2);
    });

    this.amountTarget.addEventListener("blur", (e) => {
      this.amountTarget.value = parseFloat(e.target.value, 10).toFixed(2);
      this.tipAmountTarget.value = this.totalTip.toFixed(2);
      this.setSelectedQuickAction(parseFloat(this.amountTarget.value, 10));
      this.lastUsed = "amount";
    });

    this.tipAmountTarget.addEventListener("blur", (e) => {
      this.tipAmountTarget.value = (
        parseFloat(e.target.value, 10) || 0
      ).toFixed(2);
    });

    const parsedAmount = Number(this.amountTarget.value);
    this.updateTotal();


    this.setDefaultSelectedTip();
  }

  connect() {
    const keyUpMethod = debounce((e) => {
      this.fetchOrganizations({ term: e.target.value })
    }, 250)

    window.addEventListener("DOMContentLoaded", () => {
      $('.dropdownTrigger').click((e) => {
        e.preventDefault()
        const targetClassName = typeof e.target.className === 'string' ? e.target.className : e.target.className.baseVal
        const button = targetClassName.includes('dropdownTrigger') ? e.target : e.target.closest('.dropdownTrigger')
        const shouldTriggerAjax = button.className.includes('-ajax')
        const parent = button.closest('.dropdownParent')
        parent.className = parent.className.includes('active') ? parent.className.replace(' active', '') : `${parent.className} active`
        if (shouldTriggerAjax && parent.className.includes('active')) {
          this.fetchOrganizations({ term: '' })
        } else this.showDropdown()
      })

      $('.dropdownInput').on('keyup', keyUpMethod)
    });

    this.amountTarget.addEventListener("keyup", (e) => {
      this.validateGrantRequestTotalAmount();
    });

    this.totalTarget.addEventListener("keyup", (e) => {
      this.validateGrantRequestTotalAmount();
    });

    this.totalTarget.addEventListener("change", (e) => {
      this.validateGrantRequestTotalAmount();
    });

    this.tipAmountTarget.addEventListener("change", (e) => {
      this.validateGrantRequestTotalAmount();
    });

    this.tipAmountTarget.addEventListener("keyup", (e) => {
      this.validateGrantRequestTotalAmount();
    });
  }

  selectOrganization(id, name, city, state) {
    const inputs = document.querySelectorAll('.dropdownInput')
    const hiddenInputs = document.querySelectorAll('.hiddenOrganizationId')
    inputs.forEach((input) => {
      input.value = name
      if(city && state)
        input.value += ` | ${city}, ${state}`;
    })
    hiddenInputs.forEach((input) => {
      input.value = id
    })
    this.showDropdown()
    this.hideAddNewOrg()
  }

  getNodes(results) {
    const nodes = []
    results.forEach((result) => {
      nodes.push(this.createNode(result))
    })
    nodes.push(this.createNode({ id: 'new', text: 'Suggest a charity' }, () => {
      this.selectOrganization('new', 'Suggest a charity', null, null)
      this.showAddNewOrg()
    }))
    return nodes
  }

  createNode(result, onClick) {
    const node = document.createElement("LI");
    node.className = "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
    node.innerHTML = result.text
    if(result.city){
      const locationNode = document.createElement("div");
      locationNode.className = "text-gray-500";
      locationNode.innerHTML = `${result.city}, ${result.state}`;
      node.appendChild(locationNode);
    }
    node.onclick = typeof onClick == 'function' ? onClick : () => { return this.selectOrganization(result.id, result.name, result.city, result.state) }
    return node
  }

  showAddNewOrg() {
    const addNewCharity = document.getElementById('addNewCharity')
    addNewCharity.classList.remove('hidden')
  }

  hideAddNewOrg() {
    const addNewCharity = document.getElementById('addNewCharity')
    addNewCharity.classList.add('hidden')
  }

  showDropdown() {
    setTimeout(() => {
      const dropdowns = document.querySelectorAll('.dropdownParent .dropdown')
      dropdowns.forEach((dropdown) => {
        if (dropdown.className.includes('hidden')) dropdown.className = `${dropdown.className.replace(' hidden', '')}`
        else dropdown.className = `${dropdown.className} hidden`
      })
    }, 100)
  }

  formatState(nodes = null) {
    setTimeout(() => {
      const dropdowns = document.querySelectorAll('.dropdownParent .dropdown')
      dropdowns.forEach((dropdown) => {
        dropdown.closest('.dropdownParent').classList.add('active')
        dropdown.className = `${nodes ? dropdown.className.replace(' hidden', '') : `${dropdown.className} hidden`}`
        const content = dropdown.querySelector('.content')
        content.innerHTML = ''
        nodes.forEach((node) => { content.appendChild(node) })
      })
    }, 100)
  }

  validateGrantRequestTotalAmount() {
    let amountAndAccount

    if (this.hasPredefinedSourceTarget) {
      amountAndAccount = this.predefinedSourceTarget.innerHTML
    } else {
      // Obtain Pay From element
      let amountAvailableElement = document.getElementById("balance-select");
      // Get the selected account/balance
      amountAndAccount =
        amountAvailableElement.options[amountAvailableElement.selectedIndex]
          .innerHTML;
    }
    // Get the balance
    let amountWithDecimal = amountAndAccount.split("-")[1].trim();

    // Remove dollar sign and comma from the amount
    let amountString = amountWithDecimal.replace(/,|\$/g, "");
    let amountChosenString = document.getElementById("grant_request_amount")
      .value;
    let totalAmountString = document.getElementById("grant_request_total")
    .value;

    let amount = parseFloat(amountString);
    let total = parseFloat(totalAmountString);
    let reviewButton = document.getElementById("review-button");
    let buttonId;

    if(reviewButton !== null) {
      buttonId = "review-button"
    } else{
      buttonId = "update-button"
    }

    if (Number(total) > Number(amount)) {
      this.disableReview(buttonId);
    } else {
      this.enableReview(buttonId);
    }
  }

  disableReview(buttonId) {
    let button = document.getElementById(buttonId);
    let totalAmountWarning = document.getElementById("total-warning");

    if(button.disabled === false) {
      button.disabled = true;

      button.classList.add("text-brandalert");
      button.classList.remove("text-white");

      button.classList.add("cursor-not-allowed");
      button.classList.remove("cursor-pointer");

      totalAmountWarning.hidden = false;
    }
  }

  enableReview(buttonId) {
    let button = document.getElementById(buttonId);
    let totalWarning = document.getElementById("total-warning");

    if (button.disabled === true) {
      button.disabled = false;

      button.classList.remove("text-brandalert");
      button.classList.add("text-white");

      button.classList.remove("cursor-not-allowed");
      button.classList.add("cursor-pointer");

      totalWarning.hidden = true;
    }
  }
}
