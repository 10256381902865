import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return ['source']
  }

  copy(e) {
    e.preventDefault();

    try {
      const element = document.createElement('textarea');
      element.value = this.sourceTarget.value;
      document.body.appendChild(element);
      element.select();
      document.execCommand('copy');
      document.body.removeChild(element);

      document.getElementById("copy-success").style.display = "inline";
      setTimeout( function() {
        document.getElementById("copy-success").style.display = "none";
      }, 500);
    } catch (error) {
        document.getElementById("copy-error").style.display = "inline";
      setTimeout( function() {
        document.getElementById("copy-error").style.display = "none";
      }, 500);
    }
  }
}
