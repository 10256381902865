import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];
  initialize() {
    this.setupTribute();
  }
  setupTribute() {
    var check = this.element.querySelector("#tribute");
    if (check) {
      check.addEventListener("change", (event) => {
        this.toggleTributePanel(event.target.checked);
      });
    }
    var occasion = this.element.querySelector(
      "#contribution_occasions"
    );
  }
  toggleTributePanel(visible) {
    var panel = this.element.querySelector("#tribute-panel");
    this.toggle(panel, visible);
  }
  toggle(el, visible) {
    if (visible) {
      el.classList.remove("hidden");
    } else {
      el.classList.add("hidden");
    }
  }
}
