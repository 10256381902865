import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["overlay", "toggleable", "mobile"];

  toggle() {
    this.toggleableTarget.classList.toggle("hidden");
    this.overlayTarget.classList.toggle("hidden");
  }

  mobileToggle(event) {
    event.preventDefault();
    let dropDown = document.getElementById("mobile-dropdown-container");
    dropDown.classList.toggle("hidden");
    let overlay = document.getElementById("mobile-overlay");
    overlay.classList.toggle("hidden");
  }
}
